.languagepickerwrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  justify-content: space-between;
  padding: 24px 0px;
  box-sizing: border-box;
}

.languagelist {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
  row-gap: 16px;
  margin-top: 40px;
}

.languagelist {
  font-size: 16px;
}

.languagelist-3 {
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4px;
}

.languagelist-3 p {
  font-size: 13px;
}

.languageswitchercard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  border-radius: 12px;
}

.languageswitchercard-active {
  background-color: #0001;
}

.languageswitchercard img {
  width: 64px;
  height: 64px;
  border-radius: 100%;
}

.languageswitchercard p {
  margin: 0px auto;
  margin-top: 16px;
}

.consenttext {
  text-align: center;
  font-size: 12px;
  margin-bottom: 24px;
  margin-top: 24px;
  color: #666;
}

.consenttext a {
  color: inherit;
  text-decoration: underline;
}

/* NEW */



.onboardingbuttonsection {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding-bottom: 32px;
}

.onboardingheadline {
  font-size: 28px;
  margin: 20px 0px;
  font-weight: bold;
  line-height: 120%;
}

.onboardingtext {
  font-size: 18px;
}

.carousel-container {
  overflow-x: hidden;
  display: flex;
}

.carousel-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}


.onboardingslide {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  box-sizing: border-box;
  text-align: center;
  height: 100vh;
  height: 100dvh;
  width: 100vw; /* Ensure each slide takes full viewport width */
}

.onboardingsliderdots {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.onboardingsliderdot {
  width: 20px;
  height: 3px;
  background-color: #000;
  border-radius: 20px;
  margin: 0px 4px;
  opacity: 0.2;
  transition: opacity 0.2s;
}

.onboardinglanguageinfo {
  position: absolute;
  top: 32px;
  left: 32px;
  transition: opacity 0.2s;
}

.onboardinglanguageinfo img {
  width: 32px;
  border-radius: 100%;
}