.page-success {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
}

.wrapper-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: 100dvh;
  text-align: center;
  padding: 40px 0px;
  box-sizing: border-box;
  overflow: hidden;
}

.badgesuccesswrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.badgesuccess {
  /*animation: badgesuccessshadow 3s infinite ;*/
  width: 120px;
  height: 120px;
  z-index: 2;
  animation: badgesuccess 3s cubic-bezier(0.2, 0.6, 0.4, 1) infinite ;
}

.badgesuccessshine {
  position: absolute;
  animation: rotate 10s linear infinite;
  width: 400px;
  height: 400px;
  z-index: -1;
}

.badgesuccess2 {
  width: 120px;
  height: 120px;
  position: absolute;
  z-index: 3;
  /*animation: badgesuccess 3s linear(0 0%, 0 2.27%, 0.02 4.53%, 0.04 6.8%, 0.06 9.07%, 0.1 11.33%, 0.14 13.6%, 0.25 18.15%, 0.39 22.7%, 0.56 27.25%, 0.77 31.8%, 1 36.35%, 0.89 40.9%, 0.85 43.18%, 0.81 45.45%, 0.79 47.72%, 0.77 50%, 0.75 52.27%, 0.75 54.55%, 0.75 56.82%, 0.77 59.1%, 0.79 61.38%, 0.81 63.65%, 0.85 65.93%, 0.89 68.2%, 1 72.7%, 0.97 74.98%, 0.95 77.25%, 0.94 79.53%, 0.94 81.8%, 0.94 84.08%, 0.95 86.35%, 0.97 88.63%, 1 90.9%, 0.99 93.18%, 0.98 95.45%, 0.99 97.73%, 1 100%) forwards;*/
  animation: badgesuccess 3s ease-out forwards;
  transform-style: flat;
  -webkit-transform-style: flat;
}

.badgesuccessdarken {
  width: 100%;
  height: 100%;
  background-color: #000;
  animation: badgesuccessdarken 3s ease-out forwards;
}

.badgesuccesstext {
  margin-top: 8px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  animation: fadein 1s linear forwards;
  animation-delay: 2s;
}

.badgesuccesstext h2 {
  font-size: 32px;
}


.badgesuccessshadow {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  background-color: #0004;
  position: absolute;
  top: 12px;
  left: 16px;
  z-index: 1;
  animation: badgesuccessshadow 3s cubic-bezier(0.22, 0.61, 0.36, 1) infinite ;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}



@keyframes badgesuccess {
    0% {
      transform: scale(4) rotateY(80deg);
      box-shadow: 0px 8px 16px #0005, inset 4px 4px 4px #0003, 16px 0px 0px #aaa;
    }
    100% {
      transform: scale(1) rotateY(0deg);
      box-shadow: 0px 8px 16px #0005, inset 4px 4px 4px #0003, 0px 0px 0px #666;
    }
}

@keyframes badgesuccessdarken {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes badgesuccessshadow {
  0% {
    filter: blur(24px);
    transform: translateX(240px) translateY(240px) scale(3);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    filter: blur(4px);
    transform: translateX(0px) translateY(0px);
  }
}

@keyframes badgesuccessshadow_old {
  0% {
    box-shadow: 32px 40px 40px #0003;
  }
  100% {
    box-shadow: 0px 8px 16px #0005;
  }
}

.modalfeedback {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0009;
  backdrop-filter: blur(4px);
  z-index: 6;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s;
  visibility: visible;
  transition-timing-function: cubic-bezier(0.65,0.05,0.36,1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalfeedbackclosed {
  opacity: 0;
  visibility: hidden;
}

.modalfeedbackinner {
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.modalfeedbackstarcontainer {
  display: flex;
  flex-direction: row;
}

.modalfeedbackstarcontainer img {
  width: 48px;
  transition: opacity 0.1s, transform 0.1s;
}

.modalfeedbackstarcontainer img:active {
  transform: scale(0.8);
}

.feedbacktext {
  width: 95%;
  height: 100px;
  margin-top: 24px;
  border-radius: 12px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 14px;
  padding: 16px;
  box-sizing: border-box;
  resize: none;
}

@keyframes fadein {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}