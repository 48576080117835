.page-station {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #000;
  overflow: hidden;
  position: fixed;
}

.page-station .wrapper-success {
  overflow: visible;
}


.content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  transition: transform 0.3s;
}

.content-wrapper-before {
  transform: translateX( 100%);

}

.content-wrapper-after {
  transform: translateX(-100%);

}

.contentarrowcontainer {
  position: fixed;
  width: 100%;
  bottom: 0px;
  display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 16px;
 box-sizing: border-box;
 transition: all 0.2s;
 z-index: 5;
 /*background: rgb(0,0,0);
background: linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);*/
}

.contentarrowcontainerhidden {
  opacity: 0;
  visibility: hidden;
}

.contentarrow {
  background-color: #fff;
  border-radius: 100%;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 6px;
  box-shadow: 0px 4px 12px #0001;
  transition: transform 0.2s;
}

.contentarrow:active {
  transform: scale(0.8);
}



.contentarrowback img {
  transform: rotate(180deg);
}

.contentarrow img {
  width: 32px;
  height: 32px;
}

.progressdotcont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.progressdot {
  width: 8px;
  height: 8px;
  opacity: 0.5;
  background-color: #fff;
  border-radius: 20px;
  margin: 2px;
  box-shadow: 0px 4px 12px #0001;
  transition: all 0.2;
}

.progressdotactive {
  width: 12px;
  height: 12px;
  opacity: 1;
}

.stationbtn {
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 6px;
  box-shadow: 0px 2px 0px #0002;
  transition: all 0.2s;
}

.stationnextbtn {
  background-color: #fff;
  padding: 12px 16px 12px 24px;
  font-size: 18px;
}

.stationnextbtn img {
  width: 28px;
  height: 28px;
}

.stationnextbtn .arrowmove {
  animation: arrowmove 1s ease-in-out alternate infinite;
}

@keyframes arrowmove {
  from {transform: translateX(0px);}
  to {transform: translateX(6px);}
}

.stationprevbtn {
  background-color: #fffa;
  backdrop-filter: blur(4px);
  padding: 12px;
}

.stationprevbtn img {
  width: 28px;
  height: 28px;
  transform: rotate(180deg);
}

/* STATION SUCCESS */

.page-stationsuccess {
  background-color: #fff;
}

.stationmarkersuccess {
  width: 100px;
}

/* VIDEO */

.page-content-video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #eee;
}

.page-content-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.videoplayicon {
  background-color: #000a;
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  z-index: 5;
  transition: opacity 0.2s, transform 0.2s;
}

.videoplayicon img {
  width: 40px;
}

.videoplayiconhidden {
  opacity: 0;
  transform: scale(0.5);
}

.videoprogress {
  background-color: #0005;
  width: 100%;
  height: 6px;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(8px);
}

.videoprogressinner {
  width: 0%;
  height: 100%;
  background-color: #fff;
}

.videocontrols {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  position: absolute;
  transition: all 0.2s;
}

.videocontrolshidden {
  opacity: 0;
  transform: scale(0.5);
  visibility: hidden;
  
}

/* AUDIO */

.page-content-audio {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #222;
}

.audioplayicon {
  background-color: #fff;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  box-shadow: 0px 8px 16px #0002;
}

.audioplayicon img {
  width: 56px;
}

.audiocontrols {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  position: absolute;
  bottom: 140px;
}

.seekcontrolimage {
  background-color: #000a;
  backdrop-filter: blur(8px);
  padding: 12px;
  border-radius: 100%;
  margin: 12px;
  width: 40px;
  opacity: 0.8;
}

/* The slider itself */
.audiobar {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 240px; /* Full-width */
  height: 6px; /* Specified height */
  background: #fff5; /* Grey background */
  backdrop-filter: blur(4px);
  border-radius: 20px;
  outline: none; /* Remove outline */
  margin-top: 32px;
}

.page-content-audio .audiobackground {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  object-fit: cover;
  position: absolute;
  filter: brightness(60%)
}



/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.audiobar::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #fff; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 20px;
  box-shadow: 0px 4px 8px #0003;
}

.audiobar::-moz-range-thumb {
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #fff; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 20px;
  box-shadow: 0px 4px 8px #0003;
}


/* QUIZ */

.page-content-quiz {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
}

.page-content-quiz-wrapper {
  width: 85%;
  max-width: 600px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 40px 0px 100px 0px;
}

.page-content-quiz-wrapper h1 {
  font-size: 28px;
  line-height: 120%;
  margin-bottom: 32px;
}

.quiz-box {
  display: grid;
  row-gap: 12px;
  column-gap: 12px;
}

.quiz-box-two {
  grid-template-columns: 1fr 1fr;
}

.quiz-item {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  position: relative;
  /*box-shadow: 0px 4px 12px #0001;*/
  border: 2px solid #eee;
  border-bottom: 5px solid #eee;
}

.quizitemimage {
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
}


.quizitemtext {
  padding: 16px;
  line-height: 140%;
}


.quiz-item.correct {
  /*background-color: #3b873b; /* Bright green background for correct answers */
  /*border-color: #3b873b; /* Darker green border color */
  color: #135113; /* Dark green text color */
  animation: pulse 1s;
  border-color: #9cc79c;
}

.quiz-item.correct::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #0a890a30;
  position: absolute;
  top: 0;
  left: 0;
}

.quiz-item::after {
  content: "";
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 15px;
  right: 14px;
}

.quiz-item.correct::after {
  background-image: url("/src/img/quiz-correct.svg");
  animation: checkiconanimation 0.5s;
}

.quiz-item.incorrect {
  /*background-color: #fbe4e4; /* Bright red background for incorrect answers */
  /*border-color: #f3c0be; /* Darker red border color */
  color: #732221; /* Dark red text color */
  animation: shake 0.5s; /* Apply the shake animation */
  border-color: #d19493;
}

.quiz-item.incorrect::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #85050230;
  position: absolute;
  top: 0;
  left: 0;
}

.quiz-item.incorrect::after {
  background-image: url("/src/img/quiz-incorrect.svg");
  animation: checkiconanimation 0.5s;
}

@keyframes checkiconanimation {
  0% {
    transform: scale(3); /* Normal size */
    opacity: 0;
  }
  100% {
    transform: scale(1); /* Slightly larger size */
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-5px); /* Move to the left */
  }
  40% {
    transform: translateX(5px); /* Move to the right */
  }
  60% {
    transform: translateX(-5px); /* Move to the left */
  }
  80% {
    transform: translateX(5px); /* Move to the right */
  }
  100% {
    transform: translateX(0); /* Back to center */
  }
}


@keyframes pulse {
  0%,
  100% {
    transform: scale(1); /* Normal size */
  }
  50% {
    transform: scale(1.05); /* Slightly larger size */
  }
}

/* IMAGE */

.page-content-image img {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  object-fit: cover;
}

/* CAMERA STICKER */

.page-content-camera {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
}

.page-content-camera .camerasticker {
  position: absolute;
  width: 80vw;
  height: calc(100vh - 240px);
  height: calc(100dvh - 240px);
  object-fit: contain;
}

.camerastickerslider {
  position: absolute;
  top: 40px;
}


/* EXTERNAL */

.page-content-external {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.page-content-external iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* GAME */

.page-content-game {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.page-content-game iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.content-game-overlay {
  position: absolute;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #000a;
  backdrop-filter: blur(12px);
  color: #fff;
  opacity: 1;
  visibility: visible;
  transition: all 0.2s;
}

.content-game-overlay-closed {
  opacity: 0;
  visibility: hidden;
}

.content-game-overlay h2 {
  margin: 0px auto;
  font-size: 32px;
  margin-bottom: 12px;
  font-weight: bold;
}

.content-game-overlay p{
  margin: 0px auto;
  font-size: 16px;
  margin-bottom: 24px;
}

.playagainbtn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stopgamebutton {
  position: absolute;
    background-color: #fff;
    top: 20px;
    right: 20px;
    padding: 12px;
    border-radius: 40px;
    box-shadow: 0px 2px 0px #0002;
}

.stopgamebutton img {
  width: 28px;
  height: 28px;
  display: block;
}

/* SCRATCH */

.page-content-scratch {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.scratchbackground {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scratchicon {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 5;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s;
}

.scratchicon p {
  width: 90%;
  max-width: 600px;
  text-align: center;
  font-size: 20px;
  line-height: 1.4;
}

.scratchiconremoved {
  opacity: 0;
  visibility: hidden;
}

.scratchiconimg {
  width: 100px;
  animation: scrub-motion 0.6s ease-in-out infinite alternate;
}

@keyframes scrub-motion {
  0% {

    transform: translate(10px, -10px) rotate(4deg); /* Move to upper right */
  }
  100% {
    transform: translate(-10px, 10px) rotate(-4deg);
  }
}

/* Successscreen */

.successscreenstationbubblecontainer {
  display: flex;
  justify-content: center;
  gap: 8px; /* Space between bubbles */
  width: 100%; /* Full width of container */
}

.successscreenstationbubble {
  flex: 1 1 40px; /* Start with 40px width but allow scaling */
  max-width: 40px; /* Ensure bubbles do not exceed 40px */
  aspect-ratio: 1;
  border-radius: 50%; /* Circular shape */
  background-color: #0003;
  background-size: cover;
  background-position: center;
  backdrop-filter: blur(8px);
}

.successscreenstationbubbledone {
  animation: successscreenstationbubbledone 1s ease-in-out 0s both;
}

@keyframes successscreenstationbubbledone {
  0% {
    opacity: 0.3;
    filter: grayscale(1);
    scale: 1;
    transform: translateY(0px);
  }
  40% {
    opacity: 1;
    filter: grayscale(0);
    scale: 1.2;
    transform: translateY(-20px);
  }
  80% {
    scale: 0.9;
    transform: translateY(4px);
  }
  100% {
    scale: 1;
    transform: translateY(0px);
  }
}

.successscreenstationbubbleinactive {
  opacity: 0.3;
  filter: grayscale(1);
}
